import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import axios from 'axios';
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../components/Common/Header';
import Show from '../components/Common/show';
import Sidebar from '../components/Common/Sidebar';
import SurveyForm from '../components/Service/Surveyform';
import placeholder_img from '../images/camera_img.svg';
import { loaderActions } from '../state/actions';
const SurveyFormContext = createContext();
export const useAddNewServiceSurveyForm = () => useContext(SurveyFormContext);
const profile = localStorage.getItem('profile');
const company_name = localStorage.getItem('company_name');

function AddNewService({ isProgress }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation(); // To get query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const isReadOnly = queryParams.get('readOnly') === '1';
  const workIdFromURL = queryParams.get('workId');

  React.useEffect(() => {
    if (!localStorage.getItem('vinnData')) {
      navigate('/vinn');
    } else {
    }
  }, []);
  const data = localStorage.getItem('vinnData');
  const profile_id = localStorage.getItem('profile_id');
  const parsed_data = JSON.parse(data);
  const db_data = parsed_data?.data;
  const seriesCode = parseInt(db_data?.Factory_name.slice(2).split('-')[0]);
  const [selectedprevMileage, setprevSelectedMileage] = useState('');
  const [prevCurrentMileage, setprevCurrentMileage] = useState('');
  const [selectedMileage, setSelectedMileage] = useState(
    isProgress || isReadOnly
      ? JSON.parse(localStorage.getItem('mileageEnteredForCheckList')) || ''
      : ''
  );

  const getUserNameById = (id) => {
    const user = users.find((user) => user.id === id);
    return user ? user.name : 'User not found';
  };

  const submitWorkForm = async (profile, company_name, work_id) => {
    try {
      dispatch(loaderActions.showLoader());

      const formdata = new FormData();
      formdata.append(
        'description',
        `Maintenance made by ${profile} from ${company_name}`
      );
      formdata.append(
        'estonian_description',
        `Hooldus on teostatud ${profile} poolt, ettevõttest ${company_name}`
      );
      formdata.append(
        'latvian_description',
        `Apkope veikta ${profile} no uzņēmuma ${company_name}.`
      );
      formdata.append(
        'finnish_description',
        `Huoltopalvelu on toteutettu ${profile} toimesta, yrityksestä ${company_name}.`
      );
      formdata.append('keyword', 'Maintenance');

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/update-history/${work_id}/`,
        requestOptions
      );
      const result = await res.text();
      console.log(result);
    } catch (error) {
      toast.error('Error while updating history', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const [maintenanceData, setMaintenanceData] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const handleUserChange = async (userId) => {
    setSelectedUser(userId);
  };

  console.log('mic', maintenanceData);

  useEffect(() => {
    const company_id = localStorage.getItem('company_id');

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/user/users/?company_id=${company_id}`
      )
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
        toast.error('Error fetching users');
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0'); // Ensure day is 2 digits (e.g., '03' instead of '3')
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const year = date.getFullYear();
    const hour = String(date.getHours()).padStart(2, '0'); // Ensure hour is 2 digits
    const minute = String(date.getMinutes()).padStart(2, '0'); // Ensure minute is 2 digits

    return `${day}/${month}/${year} at ${hour}:${minute}`;
  };

  console.log(selectedMileage, 'here at selectedMileage');
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [postSummaryData, setPostSummaryData] = useState({});

  console.log(seriesCode, 'here at series code');

  useEffect(() => {
    // Function to handle the second API call
    const fetchMaintenanceList = (factoryNamereq) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/maintenance-lists/factory/${db_data?.Factory_name}/${factoryNamereq}/`
        )
        .then((res) => {
          setLoading(false);
          getSpareParts();
          console.log('che', res?.data);
          setMentainance(res?.data[0]);
          localStorage.setItem('bulletinData', JSON.stringify(res?.data[0]));
          if (res.data && res.data[0].mileage) {
            const mileageOptions = res.data[0].mileage.map((m) => ({
              value: m.Mileage,
              label: `${m.Mileage} Km`,
            }));
            setMileageOptions(mileageOptions);
          }

          setAvailable(true);
        })
        .catch((error) => {
          console.error('Error fetching maintenance lists:', error);
        });
    };

    // First API call
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/vehicle-maintenance-history/${db_data?.vin_code}/`
      )
      .then((response) => {
        let factoryNamereq;

        if (!response.data || response.data.length === 0) {
          // Handle case where no vehicle maintenance history is found
          setprevSelectedMileage(null); // or set to default value
          setprevCurrentMileage(null); // or set to default value
          setFactoryName('1st');
          setLoading(false);
          factoryNamereq = '1st'; // Default to '1st' if no history
        } else {
          const historyLength = response.data.length;

          if (historyLength === 1) {
            factoryNamereq = '2nd';
            setFactoryName('2nd');
          } else if (historyLength === 2) {
            factoryNamereq = '3rd';
            setFactoryName('3rd');
          } else if (historyLength === 3) {
            factoryNamereq = '4th';
            setFactoryName('4th');
          } else if (historyLength === 4) {
            factoryNamereq = '5th';
            setFactoryName('5th');
          } else if (historyLength === 5) {
            factoryNamereq = '6th';
            setFactoryName('6th');
          } else if (historyLength === 6) {
            factoryNamereq = '7th';
            setFactoryName('7th');
          } else if (historyLength === 7) {
            factoryNamereq = '8th';
            setFactoryName('8th');
          } else if (historyLength === 8) {
            factoryNamereq = '9th';
            setFactoryName('9th');
          } else if (historyLength === 9) {
            factoryNamereq = '10th';
            setFactoryName('10th');
          } else {
            factoryNamereq = '0';
            setFactoryName('0'); // Optionally handle lengths greater than 10 differently
          }

          // Set previous selected mileage
          setprevSelectedMileage(
            response.data[response.data.length - 1].next_mileage
          );

          setprevCurrentMileage(
            response.data[response.data.length - 1].current_mileage
          );
        }

        // Make the second API call with the determined factoryNamereq

        fetchMaintenanceList(factoryNamereq);
      })
      .catch((error) => {
        console.error('Error fetching vehicle maintenance history:', error);
      });
  }, [db_data?.vin_code, db_data?.Factory_name]);

  const product_id = db_data?.id;
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const image_url = `${db_data?.image}`;
  const [openSidebar, setOpenSidebar] = useState(false);
  const dispatch = useDispatch();

  const [MileageOptions, setMileageOptions] = useState([]);

  const [requiredata, setrequiredata] = useState([]);
  const [comment, setComment] = useState(
    isProgress || isReadOnly
      ? localStorage.getItem('mechanicComment') || ''
      : ''
  );

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [files, setFiles] = useState([null, null, null, null]);

  const [mentainence, setMentainance] = useState({});
  const [available, setAvailable] = useState(false);
  const [latestWorkData, setLatestWorkData] = useState(null);
  const [workStatusData, setWorkStatusData] = useState(null);

  const timers = useRef([]);
  const [services, setServices] = useState(
    JSON.parse(localStorage.getItem('servicesCheckList')) || []
  );

  const [fillvalue, setfillValue] = useState({});
  const [valvalue, setvalValue] = useState([]);

  const [loading, setLoading] = useState(true);

  ///////
  const [isYesChecked, setIsYesChecked] = useState({});
  const [isChecked, setIsChecked] = useState({});
  const [textValue, setTextValue] = useState({});

  const timerRef = useRef([]);
  const [spareParts, setSpareParts] = useState([]);
  const [timer, setTimer] = useState({});

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const [factoryName, setFactoryName] = useState('');

  const handleImageClick = (ref) => {
    ref.current.click();
  };

  const handleFileSelect1 = (event) => {
    setSelectedFile1(URL.createObjectURL(event.target.files[0]));
    files[0] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect2 = (event) => {
    setSelectedFile2(URL.createObjectURL(event.target.files[0]));
    files[1] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect3 = (event) => {
    setSelectedFile3(URL.createObjectURL(event.target.files[0]));
    files[2] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect4 = (event) => {
    setSelectedFile4(URL.createObjectURL(event.target.files[0]));
    files[3] = event.target.files[0];
    setFiles([...files]);
  };

  const handleSetIsCheckedChange = (checked, index) => {
    isChecked[index] = checked;
    // let int;
    setIsChecked((prev) => ({ ...prev, [index]: checked }));
  };

  const handleTextChange = (event, index) => {
    setTextValue((prev) => ({ ...prev, [index]: event.target.value || '' }));
  };
  const handleFillChange = (event, index) => {
    setfillValue((prev) => ({ ...prev, [index]: event.target.value || '' }));
  };
  const handleValvalueChange = (event, index) => {
    setvalValue((prev) => ({ ...prev, [index]: event.target.value || '' }));
  };

  const startTimerController = (index) => {
    const alreadyStarted = timerRef.current[index];

    if (!alreadyStarted) {
      timerRef.current[index] = setInterval(() => {
        timers.current[index] = (timers.current[index] || 0) + 1;

        setTimer((prev) => ({ ...prev, [index]: (prev[index] || 0) + 1 }));
      }, 1000);
    }
  };
  const endTimerController = (index) => {
    const alreadyStarted = timerRef.current[index];

    if (alreadyStarted) {
      const timerValue = timers.current[index];

      if (!timerValue) {
        timers.current[index] = 0;
      }

      clearInterval(timerRef.current[index]);
    }
  };

  const handleYesCheckboxToggle = (checked = false, index) => {
    setIsYesChecked((prev) => ({ ...prev, [index]: checked }));
    if (checked) startTimerController(index);
    else endTimerController(index);
  };

  const saveServices = async (e) => {
    e.preventDefault();
    const data = [];

    var time_sum = 0;
    for (const index in isChecked) {
      if (Object.hasOwnProperty.call(isChecked, index)) {
        const value = isChecked[index];

        if (value) {
          data.push({
            is_active: isYesChecked[index],
            comment: textValue[index] || '',
            executed: isChecked[index] || '',
            fill: fillvalue[index] || '',
            value: valvalue[index] ? parseInt(valvalue[index]) : '',
            product: product_id,
            name: services.data[index].Maintainencepoint_name,
            time_spent: timerRef.current[index],
            user: profile_id,
          });
          time_sum += timerRef.current[index];
        }
      }
    }

    if (data.length == 0) {
      // alert("Services is empty!")
      return;
    } else {
      try {
        dispatch(loaderActions.showLoader());
        const res = await axios.post(
          // `${process.env.REACT_APP_API_URL}/product-service/create/`,
          `${process.env.REACT_APP_API_URL}/create_product_services/`,
          // `${process.env.REACT_APP_API_URL}/product-service/create/`,
          data
        );

        if (res) {
          toast.success('Service created successfully');
          setPostSummaryData((prevData) => ({
            ...prevData,
            product_services: res.data?.created_ids,
          }));
          console.log('response of services ', res);
        }
        var formdata = new FormData();
        formdata.append(
          'description',
          `Maintenance made by ${profile} from ${company_name}`
        );
        formdata.append(
          'estonian_description',
          `Hooldus on teostatud ${profile} poolt, ettevõttest ${company_name}`
        );
        formdata.append(
          'latvian_description',
          `Apkope veikta ${profile} no uzņēmuma ${company_name}.`
        );
        formdata.append(
          'finnish_description',
          `Huoltopalvelu on toteutettu ${profile} toimesta, yrityksestä ${company_name}.`
        );
        formdata.append('historical_note', ``);
        formdata.append('vehicle', db_data?.id);
        formdata.append('owner_email', db_data?.orderer_email || '');
        formdata.append('plate_number', db_data?.plate_number);
        formdata.append('vin_code', db_data?.vin_code);
        formdata.append('work_id', latestWorkData?.work_id || null);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };
        fetch(
          `${process.env.REACT_APP_API_URL}/history/create/`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => toast.success('History created successfully.'))
          .catch((error) => console.log('error', error));
        // alert(" history has been created")
      } catch (error) {
        console.log('Error uploading file: ', error);
      } finally {
        dispatch(loaderActions.hideLoader());
      }
    }
  };

  const handleTemporaryMaintenanceButtonClick = async () => {
    try {
      // First API call
      const firstResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get_temporary_mechanical_notes/${db_data.id}/`
      );
      if (firstResponse.data) {
        const firstData = firstResponse.data;
        if (isProgress || isReadOnly) {
          setComment(firstData?.note || '');
        }
        if ((isProgress || isReadOnly) && firstData?.selected_mileage) {
          setSelectedMileage(firstData?.selected_mileage);
        }

        // Second API call
        const secondResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/temporary_maintenance/product/${db_data.id}/`
        );
        if (secondResponse.data) {
          const secondData = secondResponse.data;
          setrequiredata(secondData);

          // Extract and update states
          setIsYesChecked(secondData.map((item) => item.is_active));
          setTextValue(secondData?.map((item) => item?.comment || ''));
          setIsChecked(secondData.map((item) => item.executed || false));
          setfillValue(secondData.map((item) => item.fill || ''));
          setvalValue(
            secondData.map((item) => (item.value ? parseInt(item.value) : null))
          );
          // Additional actions...
        } else {
          // No data from second API call
          toast.error('No data found from the second API call', {
            /* toast options */
          });
        }
      } else {
        // No data from first API call
        toast.error('No data found from the first API call', {
          /* toast options */
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const posttempImgs = async () => {
    const data = new FormData();
    data.append('product', product_id);
    for (let i = 0; i < files.length; i++) {
      if (files[i] != null) {
        data.append(`image${i + 1}`, files[i]);
      }
    }
    if (files.length === 0) {
      // alert("Value is empty!");
      return;
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/service-image/`,
          data
        );
        if (res) {
          setPostSummaryData((prevData) => ({
            ...prevData,
            service_image: res.data?.id,
          }));
        }
        // alert("service Images has been saved");
      } catch (error) {
        console.log('Error uploading file: ', error);
      }
    }
  };

  const handletempSubmit = async (e) => {
    // e.preventDefault(); // Prevent default form submission behavior

    if (files.length > 0) {
      try {
        await savetempServices(); // No longer passing the event object
      } catch (error) {
        console.log('Error in saveServices:', error);
        // alert("Error: saveServices failed!");
        return;
      }

      try {
        await posttempImgs(e); // Assuming postImgs still needs the event object
      } catch (error) {
        console.log('Error in postImgs:', error);
        // alert("Error: postImgs failed!");
        return;
      }

      const post = {
        product: product_id,
        note: comment,
        selected_mileage: selectedMileage,
      };
      if (comment.length === 0) {
      } else {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/temporary_mechanical_notes/create`,
            post
          );

          if (res) {
            setComment('');
          }
        } catch (e) {
          // alert(e);
        }
      }
    } else {
      toast.error(`Please fill all fields`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const savetempServices = async () => {
    const data = [];

    var time_sum = 0;

    for (const index in isChecked) {
      if (Object.hasOwnProperty.call(isChecked, index)) {
        const value = isChecked[index];

        if (value) {
          data.push({
            is_active: isYesChecked[index],
            comment: textValue[index] || '',
            executed: isChecked[index] || '',
            fill: fillvalue[index] || '',
            value: valvalue[index] ? parseInt(valvalue[index]) : '',
            product: product_id,
            name: services.data[index].Maintainencepoint_name,
            estonian_name: services.data[index].Maintainencepoint_name_Estonian,
            latvian_name: services.data[index].Maintainencepoint_name_Latvian,
            finnish_name: services.data[index].Maintainencepoint_name_Finnish,
            time_spent: timerRef.current[index],
            user: profile_id,
          });
          time_sum += timerRef.current[index];
        }
      }
    }

    if (data.length === 0) {
      // alert("Services is empty!");
      return;
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/temporary_maintenance/create`, // Updated API endpoint
          data
        );

        if (res) {
          console.log('response of services ', res);
        }
        // alert("Maintenance Services have been created successfully");
      } catch (error) {
        console.log('Error uploading file: ', error);
        throw error; // Propagate the error
      }
    }
  };

  const postImgs = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('product', product_id);
    for (let i = 0; i < files.length; i++) {
      if (files[i] != null) {
        data.append(`image${i + 1}`, files[i]);
      }
    }
    if (files.length == 0) {
      // alert("Value is empty!")
      return;
    } else {
      try {
        dispatch(loaderActions.showLoader());
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/service-image/`,
          // `${process.env.REACT_APP_API_URL}/service-image/`,
          data
        );
        if (res) {
          setPostSummaryData((prevData) => ({
            ...prevData,
            service_image: res.data?.id,
          }));
          // setSelectedFile1("")
          // setSelectedFile2("")
          // setSelectedFile3("")
          // setSelectedFile4("")
        }
        // alert("service Images  has been saved")
      } catch (error) {
        console.log('Error uploading file: ', error);
      } finally {
        dispatch(loaderActions.hideLoader());
      }
    }
  };

  const cardData = async (e) => {
    e.preventDefault();
    if (!isChecked || !textValue || !isYesChecked) return;
    const post = {
      is_active: isYesChecked,
      comment: textValue,
      executed: isChecked,
      product: product_id,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/create/product-service/`,
      post
    );
    if (res.data) {
      setTextValue('');
      setIsYesChecked({});
      setIsChecked({});
    }
  };
  async function deleteTemporaryData(productId) {
    try {
      dispatch(loaderActions.showLoader());
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/temporary_maintenance/delete/${productId}/`
      );

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/temporary_mechanical_notes/delete/${productId}/`
      );
    } catch (error) {
      console.error('Error in deleting temporary data:', error);
      // alert('Error: Deletion of Temporary Data Failed!');
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  }
  const postVehicleMaintenanceHistory = async () => {
    const mileageIncrements = {
      ATV: 1000,
      SSV: 1000,
      UTV: 1000,
      '300-650': 5000,
      '700-799': 7000,
      '800-899': 15000,
      "CHILDREN'S ATV": 50,
      "CHILDREN'S MOTORCYCLE": 50,
    };

    const firstmileageIncrements = {
      ATV: parseInt(selectedMileage, 10) + 700,
      SSV: parseInt(selectedMileage, 10) + 700,
      UTV: parseInt(selectedMileage, 10) + 700,
      '300-650': parseInt(selectedMileage, 10) + 4500,
      '700-799': parseInt(selectedMileage, 10) + 6000,
      '800-899': parseInt(selectedMileage, 10) + 14000,
      "CHILDREN'S ATV": 30,
      "CHILDREN'S MOTORCYCLE": 80,
    };

    const firstNextmileageJump = {
      ATV: 700,
      SSV: 700,
      UTV: 700,
      '300-650': 4500,
      '700-799': 6000,
      '800-899': 14000,
      "CHILDREN'S ATV": 30,
      "CHILDREN'S MOTORCYCLE": 80,
    };

    const afterFirstNextmileageJump = {
      ATV: 1000,
      SSV: 1000,
      UTV: 1000,
      '300-650': 5000,
      '700-799': 7000,
      '800-899': 15000,
      "CHILDREN'S ATV": 30,
      "CHILDREN'S MOTORCYCLE": 80,
    };

    const getRangeKey = (seriesCode) => {
      if (
        db_data?.series === 'MOTORCYCLE' &&
        seriesCode >= 300 &&
        seriesCode <= 650
      )
        return '300-650';
      if (
        db_data?.series === 'MOTORCYCLE' &&
        seriesCode >= 700 &&
        seriesCode <= 799
      )
        return '700-799';
      if (
        db_data?.series === 'MOTORCYCLE' &&
        seriesCode >= 800 &&
        seriesCode <= 899
      )
        return '800-899';
      return null;
    };
    const motorcycleRangeKey = getRangeKey(seriesCode);

    const increment = motorcycleRangeKey
      ? mileageIncrements[motorcycleRangeKey]
      : mileageIncrements[db_data?.series] || 0;

    let nextMileage;
    let nextMileageJump;
    if (!selectedprevMileage) {
      if (motorcycleRangeKey) {
        nextMileage = parseInt(firstmileageIncrements[motorcycleRangeKey]);
        nextMileageJump = parseInt(firstNextmileageJump[motorcycleRangeKey]);
      } else {
        nextMileage = parseInt(firstmileageIncrements[db_data?.series]);
        nextMileageJump = parseInt(firstNextmileageJump[db_data?.series]);
      }
    } else {
      nextMileage = parseInt(selectedMileage, 10) + parseInt(increment, 10);
      if (motorcycleRangeKey) {
        nextMileageJump = parseInt(
          afterFirstNextmileageJump[motorcycleRangeKey]
        );
      } else {
        nextMileageJump = parseInt(afterFirstNextmileageJump[db_data?.series]);
      }
    }

    const vehicleMaintenanceData = {
      factory_name: db_data?.Factory_name,
      vinnumber: db_data?.vin_code,
      current_mileage: selectedMileage,
      next_mileage: nextMileage,
      date: new Date().toISOString().split('T')[0],
      next_mileage_jump: nextMileageJump,
      work_id: latestWorkData?.work_id || null,
      is_maintenance: true,
    };

    try {
      dispatch(loaderActions.showLoader());
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/vehicle-maintenance-history/`,
        vehicleMaintenanceData
      );
      if (response.status === 200 || response.status === 201) {
        toast.success('Vehicle maintenace history created successfully.');
        setPostSummaryData((prevData) => ({
          ...prevData,
          vehicle_maintenance_history: response.data?.id,
        }));
        var formdata = new FormData();
        formdata.append(
          'description',
          `Maintenance made by ${profile} from ${company_name}`
        );
        formdata.append(
          'estonian_description',
          `Hooldus on teostatud ${profile} poolt, ettevõttest ${company_name}`
        );
        formdata.append(
          'latvian_description',
          `Apkope veikta ${profile} no uzņēmuma ${company_name}.`
        );
        formdata.append(
          'finnish_description',
          `Huoltopalvelu on toteutettu ${profile} toimesta, yrityksestä ${company_name}.`
        );
        formdata.append('historical_note', ``);
        formdata.append('vehicle', db_data?.id);
        formdata.append('owner_email', db_data?.orderer_email || '');
        formdata.append('plate_number', db_data?.plate_number);
        formdata.append('vin_code', db_data?.vin_code);
        formdata.append('work_id', latestWorkData?.work_id || null);
      }
    } catch (error) {
      toast.error('Error in vehicle maintenance history creation');
      console.error('Error in vehicle maintenance history creation:', error);
      // alert("Error: vehicle maintenance history creation failed!");
      throw error;
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  };

  const [startedAt, setStartedAt] = useState(new Date().toISOString());

  const postMaintenance = async () => {
    const data = new FormData();
    data.append('add_work', latestWorkData?.work_id || null);
    data.append('status', 'ready');
    data.append('mileage_at_service_opening', selectedMileage);
    data.append('vin_code', db_data?.vin_code);
    data.append('mechanic_id', profile_id);
    data.append('started_at', startedAt);
    data.append('vin_picture', files[1]);
    data.append('speedometer_picture', files[0]);
    data.append('front_45_view_picture', files[2]);
    data.append('rear_45_view_picture', files[3]);
    data.append('error_picture', '');
    data.append('spare_parts_list_id', 0);
    data.append('maintenance_checklist_id', 0);
    data.append('mechanic_comment', comment || null);
    data.append('user', profile_id);
    data.append('user_id', profile_id);

    try {
      dispatch(loaderActions.showLoader());
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/addwork/maintenance/`,
        data
      );
      setPostSummaryData((prevData) => ({
        ...prevData,
        maintenance: response.data?.maintenance_id,
        add_work: response.data?.add_work,
        summary_comment: 'Routine maintenance completed.',
        user: profile_id,
      }));
      localStorage.removeItem('servicesCheckList');
      localStorage.removeItem('mileageEnteredForCheckList');
      console.log('Maintenance created:', response.data);
    } catch (error) {
      console.error('Error creating maintenance:', error);
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  };

  const handleSubmit = async (e) => {
    let hasErrors = false;

    // Validate images
    if (!files[0] || !files[1] || !files[2] || !files[3]) {
      toast.error(
        'Please select all required images (Speedometer, VIN, Front 45° view, and Rear 45° view)',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      hasErrors = true;
    }

    // Validate maintenance list
    if (!services?.data || !services.data.length) {
      toast.error('No maintenance list items found', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      hasErrors = true;
    } else {
      // Check if all items are checked
      const uncheckedItems = services.data.filter(
        (item) => !isChecked[services.data.indexOf(item)]
      );
      if (uncheckedItems.length > 0) {
        toast.error('Please complete all items in the maintenance list', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        hasErrors = true;
      }
    }
    if (!selectedMileage) {
      toast.error('Please enter mileage', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      hasErrors = true;
    }

    // if (!comment.trim()) {
    //   toast.error('Please enter mechanic comment', {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: 'colored',
    //   });
    //   hasErrors = true;
    // }
    if (hasErrors) {
      return;
    }
    if (files.length > 0) {
      try {
        setDisableSaveButton(true);
        await saveServices(e);
      } catch (error) {
        console.log('Error in saveServices:', error);
        // alert("Error: saveServices failed!");
        return;
      } finally {
        setDisableSaveButton(false);
      }
      try {
        setDisableSaveButton(true);
        await deleteTemporaryData(product_id);
      } catch (error) {
        console.error('Error in deleteTemporaryData:', error);
        // alert("Error: deleteTemporaryData failed!");
        return;
      } finally {
        setDisableSaveButton(false);
      }
      try {
        setDisableSaveButton(true);
        await postVehicleMaintenanceHistory();
      } catch (error) {
        console.error('Error in vehicle maintainance history:', error);
        // alert("Error: Error in vehicle maintainance history:");
        return;
      } finally {
        setDisableSaveButton(false);
      }

      try {
        setDisableSaveButton(true);
        await postMaintenance();
      } catch (error) {
        console.error('Error in maintainance post:', error);
        // alert("Error: Error in vehicle maintainance history:");
        return;
      } finally {
        setDisableSaveButton(false);
      }

      try {
        setDisableSaveButton(true);
        await postImgs(e);
      } catch (error) {
        console.log('Error in postImgs:', error);
        // alert("Error: postImgs failed!");
        return;
      } finally {
        setDisableSaveButton(false);
      }

      try {
        dispatch(loaderActions.showLoader());
        await cardData(e);
      } catch (error) {
        console.log('Error in cardData:', error);
        // alert("Error: postImgs failed!");
        return;
      } finally {
        dispatch(loaderActions.hideLoader());
        setDisableSaveButton(false);
      }

      // await deleteTemporaryData(product_id);

      e.preventDefault();
      const post = { product: product_id, note: comment };
      if (comment.length == 0) {
        // alert("Mechenical comments is empty!")
        return;
      } else {
        try {
          dispatch(loaderActions.showLoader());
          setDisableSaveButton(true);
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/mechanical-notes/create/`,
            post
          );

          var formdata = new FormData();
          formdata.append(
            'description',
            'Images and comments has been created'
          );
          formdata.append(
            'estonian_description',
            `Pildid ja kommentaarid on loodud. `
          );
          formdata.append(
            'latvian_description',
            `Attēli un komentāri ir izveidoti. `
          );
          formdata.append(
            'finnish_description',
            `Kuvat ja kommentit on luotu.`
          );
          formdata.append('historical_note', ``);
          formdata.append('vehicle', db_data?.id);
          formdata.append('owner_email', db_data?.orderer_email || '');
          formdata.append('plate_number', db_data?.plate_number);
          formdata.append('vin_code', db_data?.vin_code);
          formdata.append('work_id', latestWorkData?.work_id || null);

          if (res) {
            setComment('');
          }
        } catch (e) {
          alert(e);
        } finally {
          dispatch(loaderActions.hideLoader());
          setDisableSaveButton(false);
        }
      }
    } else {
      toast.error(`Please fill all Fields`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const postSummary = async () => {
    try {
      dispatch(loaderActions.showLoader());
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/addwork/maintenance-summaries/`,
        postSummaryData
      );
      navigate('/vehicleData');
    } catch (error) {
      console.log('Error posting summary data: ', error);
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  };

  useEffect(() => {
    const {
      maintenance,
      add_work,
      service_image,
      vehicle_maintenance_history,
      summary_comment,
    } = postSummaryData || {};

    if (
      maintenance &&
      add_work &&
      service_image &&
      vehicle_maintenance_history &&
      summary_comment
    ) {
      postSummary();
    }
  }, [postSummaryData]);

  const getSpareParts = () => {
    const res = axios
      .get(
        // `${process.env.REACT_APP_API_URL}/product-service/create/`,
        // `${process.env.REACT_APP_API_URL}/api/newspareparts/${db_data?.sku}/`,
        `${process.env.REACT_APP_API_URL}/api/newspareparts/${db_data?.sku}/`
      )
      .then((res) => {
        if (res?.data && res?.data.length > 0) {
          setSpareParts(res?.data);
        }
      });
  };
  const allAPICall = async () => {
    if (selectedMileage) {
      try {
        setDisableContinueButton(true);
        dispatch(loaderActions.showLoader());
        const res = await axios.get(
          // `${process.env.REACT_APP_API_URL}/getallservices/`
          `${process.env.REACT_APP_API_URL}/api/maintenance-lists-point/factory/${db_data?.Factory_name}/${factoryName}/`
          // `${process.env.REACT_APP_API_URL}/getallservices/?sku_name=${db_data?.sku}&mileage=${selectedMileage}`
          // `${process.env.REACT_APP_API_URL}/api/maintenance-list-point/factory/${db_data?.Factory_name}/`
        );

        setServices(res);
        localStorage.setItem('servicesCheckList', JSON.stringify(res));
        localStorage.setItem(
          'mileageEnteredForCheckList',
          JSON.stringify(selectedMileage)
        );

        toast.success('Checklist successfully populated below.');

        try {
          const updateVin = await axios.put(
            `${process.env.REACT_APP_API_URL}/api/addwork/add-work/${latestWorkData?.work_id}/`,
            {
              ...latestWorkData,
              status: 2,
            }
          );
          if (updateVin.status === 200 || updateVin.status === 201) {
            toast.success('Work status updated successfully!');
          } else {
            toast.error('Failed to update work status');
          }
        } catch (error) {
          console.error('Error updating work status:', error);
          toast.error('Error updating work status');
        }
      } catch (error) {
        toast.error('Something went wrong while fetching Checklist.');
      } finally {
        dispatch(loaderActions.hideLoader());
        setDisableContinueButton(false);
      }
    }
  };
  const dataMileage = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/mileages/`);
    const options = [];
    for (let i = 0; i < res?.data?.length; i++) {
      options.push({
        value: `${res?.data[i]?.Mileage}`,
        label: `${res?.data[i]?.Mileage} Km`,
      });
    }
    setMileageOptions(options);
  };
  useEffect(() => {
    handleTemporaryMaintenanceButtonClick();
    dataMileage();
    // getProductImages()
    getSpareParts();
  }, []);
  console.log('------->>>>>>>>>>>>>>.--', services);

  const handleConfirmService = async (name, instruction) => {
    const res = await axios.post('/services/create/', {
      name: name,
      instructions: instruction,
      instruction_active: true,
    });
    allAPICall();
  };

  const surveyFormContextValues = {
    requiredata,
    selectedMileage: selectedMileage,
    comment: comment,
    data: isReadOnly || isProgress ? services : services?.data || [],
    fillvalue,
    handleFillChange,
    valvalue,
    handleValvalueChange,
    handleYesCheckboxToggle,
    startTimerController,
    endTimerController,
    textValue,
    handleTextChange,
    handleSetIsCheckedChange,
  };
  const storedItem = localStorage.getItem('selectedLanguage');

  // Parsing the retrieved item as a JSON object
  const parsedItem = JSON.parse(storedItem);

  // Accessing the 'label' property of the parsed object
  const label = parsedItem.label;

  const firstMaintenanceSchedule = {
    ATV: 100,
    SSV: 100,
    UTV: 100,
    '300-650': 100,
    '700-799': 100,
    '800-899': 100,
    "CHILDREN'S ATV": 30,
    "CHILDREN'S MOTORCYCLE": 30, // in hours
  };

  const handleProceed = () => {
    if (!selectedMileage) {
      toast.error('Please enter mileage', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    if (!selectedprevMileage) {
      const category = db_data?.series;
      if (
        category === 'ATV' &&
        selectedMileage < firstMaintenanceSchedule['ATV']
      ) {
        toast.error(
          'First maintenance for ATV should be around 300 km or above',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        return;
      }

      if (
        category === 'SSV' &&
        selectedMileage < firstMaintenanceSchedule['SSV']
      ) {
        toast.error(
          'First maintenance for SSV should be around 300 km or above',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        return;
      }

      if (
        category === 'UTV' &&
        selectedMileage < firstMaintenanceSchedule['UTV']
      ) {
        toast.error(
          'First maintenance for UTV should be around 300 km or above',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        return;
      }

      if (
        category === 'MOTORCYCLE' &&
        seriesCode >= 300 &&
        seriesCode <= 650 &&
        selectedMileage < firstMaintenanceSchedule['300-650']
      ) {
        toast.error(
          'First maintenance for MOTORCYCLE should be around 500 km or above',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        return;
      }

      if (
        category === 'MOTORCYCLE' &&
        seriesCode >= 700 &&
        seriesCode <= 799 &&
        selectedMileage < firstMaintenanceSchedule['700-799']
      ) {
        toast.error(
          'First maintenance for MOTORCYCLE should be around 1000 km or above',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        return;
      }

      if (
        category === 'MOTORCYCLE' &&
        seriesCode >= 800 &&
        seriesCode <= 899 &&
        selectedMileage < firstMaintenanceSchedule['800-899']
      ) {
        toast.error(
          'First maintenance for MOTORCYCLE should be around 1000 km or above',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        return;
      }

      if (
        category === "CHILDREN'S ATV" &&
        selectedMileage < firstMaintenanceSchedule["CHILDREN'S ATV"]
      ) {
        toast.error(
          "First maintenance for CHILDREN'S ATV should be 30 km or above",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        return;
      }

      if (
        category === "CHILDREN'S MOTORCYCLE" &&
        selectedMileage < firstMaintenanceSchedule["CHILDREN'S MOTORCYCLE"]
      ) {
        toast.error(
          "First maintenance for CHILDREN'S MOTORCYCLE should be 30 hours or above",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        return;
      }
    }

    if (selectedMileage <= prevCurrentMileage) {
      toast.error(
        `Selected mileage must be greater than ${prevCurrentMileage} mileage`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      return;
    }

    allAPICall();
    // Add your logic for the proceed action here
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/addwork/add-work/latest-work/${db_data?.vin_code}/`
      )
      .then((response) => {
        setLatestWorkData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching latest data:', error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/addwork/add-work/check-status/${db_data?.vin_code}/`
      )
      .then((response) => {
        setWorkStatusData(response.data);
        if (!response.data.is_maintenance) {
          setDisableContinueButton(true);
          setDisableSaveButton(true);
          if (!isReadOnly && !isProgress) {
            toast.error('Maintenance cannot be added, please add work first.');
          }
        }
      })
      .catch((error) => {
        setDisableContinueButton(true);
        setDisableSaveButton(true);
        if (!isReadOnly && !isProgress) {
          toast.error('Maintenance cannot be added, please add work first.');
        }
      });
  }, []);

  const convertImageToFile = async (imageUrl, fileName = 'image.jpg') => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob(); // Fetch the image as a blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      console.error('Error converting image:', error);
    }
  };

  useEffect(() => {
    if (!isReadOnly) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/vehicle-images/${db_data?.vin_code}/`
        )
        .then((response) => {
          setSelectedFile1(response.data?.speedometer_picture || null);
          setSelectedFile2(response.data?.vin_picture || null);
          setSelectedFile3(response.data?.front_45_view_picture || null);
          setSelectedFile4(response.data?.rear_45_view_picture || null);

          const imageUrls = [
            response.data?.speedometer_picture,
            response.data?.vin_picture,
            response.data?.front_45_view_picture,
            response.data?.rear_45_view_picture,
          ];

          // Map the URLs to blobs and update state
          Promise.all(imageUrls.map((url) => convertImageToFile(url)))
            .then((blobs) => {
              setFiles(blobs); // Set files to blobs
            })
            .catch((error) => {
              console.error('Error fetching or converting images:', error);
            });
        })
        .catch((error) => {
          console.error('Error fetching temp images data:', error);
        });
    }
  }, []);

  useEffect(() => {
    const allFieldsValid =
      db_data?.vin_code && files[1] && files[0] && files[2] && files[3];

    if (allFieldsValid) {
      const formDataForTempPics = new FormData();

      formDataForTempPics.append('vin_number', db_data?.vin_code);
      formDataForTempPics.append('vin_picture', files[1]);
      formDataForTempPics.append('speedometer_picture', files[0]);
      formDataForTempPics.append('front_45_view_picture', files[2]);
      formDataForTempPics.append('rear_45_view_picture', files[3]);

      fetch(`${process.env.REACT_APP_API_URL}/api/vehicle-images/`, {
        method: 'PUT',
        body: formDataForTempPics,
      })
        .then((response) => {
          if (response.status === 404) {
            console.error('PUT request failed with 404. Attempting POST...');

            // Send the same data using POST if PUT fails with 404
            return fetch(
              `${process.env.REACT_APP_API_URL}/api/vehicle-images/`,
              {
                method: 'POST',
                body: formDataForTempPics,
              }
            )
              .then((postResponse) => postResponse.text())
              .catch((postError) => {
                console.error(
                  'Error in Temporary Vehicle Images POST:',
                  postError
                );
              });
          }
          return response.text(); // Return the response text if PUT is successful
        })
        .catch((putError) => {
          console.error('Error in Temporary Vehicle Images PUT:', putError);
        });
    } else {
      console.error('Some form data is missing or invalid');
    }
  }, [files]);

  useEffect(() => {
    if (workIdFromURL) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/addwork/maintenance-summaries/${workIdFromURL}/`
        )
        .then((res) => {
          setMaintenanceData(res?.data?.data);
          if (isProgress || isReadOnly) {
            setSelectedMileage(
              res.data?.data?.vehicle_maintenance_history?.current_mileage
            );
          }
          if (res?.data?.data?.maintenance?.user) {
            setSelectedUser(res.data.data.maintenance.user);
          }

          setSelectedFile1(
            res.data?.data?.maintenance?.speedometer_picture || null
          );
          setSelectedFile2(res.data?.data?.maintenance?.vin_picture || null);
          setSelectedFile3(
            res.data?.data?.maintenance?.front_45_view_picture || null
          );
          setSelectedFile4(
            res.data?.data?.maintenance?.rear_45_view_picture || null
          );
          setComment(res.data?.data?.maintenance?.mechanic_comment || '');
          setServices(res.data?.data?.product_services);
        })
        .catch((error) => {
          toast.error('Error fetching maintenance.');
        });
    }
  }, [workIdFromURL]);

  const handleUpdateComment = async ({ boxId, comment }) => {
    const requestData = {
      comment,
      id: boxId,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/update_product_services/${boxId}/`,
        requestData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.status === 200) {
        toast.success('Comment Updated');
      }
      console.log('Response->', response);
    } catch (error) {
      console.error('Error updating product service:', error);
    }
    console.log('boxId', boxId);
  };

  const handleUpdateMaintenance = async () => {
    try {
      dispatch(loaderActions.showLoader());
      const data = new FormData();
      data.append('mechanic_comment', comment);

      // Add user to update if changed
      if (selectedUser && selectedUser !== maintenanceData?.user?.id) {
        data.append('user', selectedUser);
      }

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/addwork/maintenance/${maintenanceData?.maintenance?.maintenance_id}/`,
        data
      );

      if (response.status === 200 || response.status === 201) {
        submitWorkForm(
          getUserNameById(selectedUser),
          company_name,
          workIdFromURL
        );
        toast.success('Mechanical comment updated successfully!');
      } else {
        toast.error('Failed to update mechanical comment');
      }
    } catch (error) {
      toast.error('Error updating mechanical comment:', error);
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  };

  return !loading ? (
    <>
      <SurveyFormContext.Provider value={surveyFormContextValues}>
        <div className='flex'>
          <Sidebar
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            onItemClick={handletempSubmit}
          />

          <Header setOpenSidebar={setOpenSidebar} />

          <main
            className={`
            md:ml-[16.25rem] xl:ml-[21.125rem]  w-full
            ${isProgress ? '' : 'pt-[62px] md:pt-[6.5625rem]'}
          `}
          >
            {/* ADD NEW SERVICE */}
            <div className='px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16'>
              <div className='flex flex-col md:flex-row mb-[22px] md:mb-[30px] xl:mb-14'>
                {/* LEFT */}
                <div className='w-full mb-10 md:mb-0 md:w-1/2 md:pt-3 xl:pt-12'>
                  {/* IMAGE */}
                  <div>
                    <img className='mx-auto' src={image_url} alt='bike-img' />
                  </div>
                </div>

                {/* RIGHT */}
                <div className='w-full md:w-1/2 flex flex-col self-center'>
                  <div className='md:pl-5 xl:pl-[30px]'>
                    {/* BIKE NAME */}
                    <div className='mb-3 md:mb-1 xl:mb-[86px]'>
                      <h3 className='bg-gradient-text text-f_19_l_24 md:text-f_22_l_28 xl:text-f_27_l_34 text-left font-supremeBold'>
                        {db_data?.model_name}, {db_data?.year}, {db_data?.color}
                      </h3>
                    </div>

                    {/* SERVICE-KM */}
                    <div className='mb-3 md:mb-[30px] xl:mb-[83px]'>
                      <h1 className=' text-blue text-f_16_l_20 md:text-f_22_l_28 xl:text-f_35_l_44 font-supremeBold'>
                        {label && label.includes('Estonian')
                          ? mentainence?.Maintenance_list_name_estonian || ''
                          : label && label.includes('Finnish')
                          ? mentainence?.Maintenance_list_name_finnish || ''
                          : label && label.includes('Latvian')
                          ? mentainence?.Maintenance_list_name_latvian || ''
                          : label && label.includes('English')
                          ? mentainence?.Maintenance_list_name || ''
                          : ''}

                        {/* {mentainence?.Maintenance_list_name} */}
                      </h1>
                    </div>
                    <div>
                      <h4 className='text-f_16_l_20 md:text-f_18_l_23 xl:text-f_22_l_28 font-supremeBold text-white-100 mb-3 md:mb-1.5 xl:mb-4'>
                        {t('waranty_milage')}
                        <span>
                          <input
                            disabled={isProgress}
                            value={selectedMileage}
                            onChange={(event) =>
                              setSelectedMileage(event.target.value)
                            }
                            type='number'
                            className='rounded-lg px-3 py-2 bg-gray-900 w-24 h-8 border border-gray-400'
                          />
                        </span>{' '}
                        km
                      </h4>
                      <Show IF={!isReadOnly}>
                        <button
                          className='
    w-full 
    max-w-[80px]  /* Reduced width */
    md:max-w-[100px]  /* Reduced width */
    bg-gradient 
    rounded-[5px] 
    text-sm 
    md:text-base 
    lg:text-lg 
    font-supremeMedium
    flex
    items-center
    justify-center
    mb-5
    disabled:cursor-not-allowed
  '
                          style={{ height: '40px', maxWidth: '300px' }} // Custom height and width
                          onClick={handleProceed}
                          disabled={disableContinueButton || isReadOnly}
                        >
                          {t('Continue')}
                        </button>
                      </Show>
                    </div>
                    {isProgress && (
                      <div className='mb-4 flex items-center gap-4'>
                        <h4 className='text-f_16_l_20 md:text-f_18_l_23 xl:text-f_22_l_28 font-supremeBold text-white-100 mb-3 md:mb-1.5 xl:mb-4'>
                          {t('mechanic')}
                        </h4>
                        <span>
                          <select
                            value={selectedUser || ''}
                            onChange={(e) =>
                              handleUserChange(parseInt(e.target.value))
                            }
                            className='rounded-lg px-3 py-2 bg-[#1B1B1B] border border-gray-600 w-[200px] xsm:w-[160px] text-white cursor-pointer appearance-none'
                            style={{
                              backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E")`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'right 12px center',
                              paddingRight: '32px',
                            }}
                            disabled={isReadOnly}
                          >
                            <option value=''>Select a user</option>
                            {users.map((user) => (
                              <option
                                key={user.id}
                                value={user.id}
                                style={{
                                  backgroundColor: '#1B1B1B',
                                  color: 'white',
                                  padding: '8px',
                                }}
                                className='hover:bg-[#00D1FF] focus:bg-[#00D1FF] selected:bg-[#00D1FF]'
                              >
                                {user.name}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                    )}

                    <div>
                      <h4 className='text-f_16_l_20 md:text-f_18_l_23 xl:text-f_22_l_28 font-supremeBold text-white-100 mb-3 md:mb-1.5 xl:mb-4'>
                        {t('Maintenance_description')}
                      </h4>
                      <p className='text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100'>
                        {label && label.includes('Estonian')
                          ? mentainence?.maintenance_description_estonian || ''
                          : label && label.includes('Finnish')
                          ? mentainence?.maintenance_description_finnish || ''
                          : label && label.includes('Latvian')
                          ? mentainence?.maintenance_description_latvian || ''
                          : label && label.includes('English')
                          ? mentainence?.Maintainence_description || ''
                          : ''}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* VEHICLE PHOTOS */}
              <div className='mb-[67px] md:mb-[41px] xl:mb-[90px]'>
                {/* TITLE */}
                <div className='mb-6 md:mb-14 xl:mb-[43px]'>
                  <p className=' text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100'>
                    {t('Vehicle_photos_desc')}
                  </p>
                </div>

                <div className='grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]'>
                  {[
                    {
                      file: selectedFile1,
                      placeholder: 'Addspeedometer',
                      ref: fileInputRef1,
                      onChange: handleFileSelect1,
                    },
                    {
                      file: selectedFile2,
                      placeholder: 'addvincode',
                      ref: fileInputRef2,
                      onChange: handleFileSelect2,
                    },
                    {
                      file: selectedFile3,
                      placeholder: 'add45front',
                      ref: fileInputRef3,
                      onChange: handleFileSelect3,
                    },
                    {
                      file: selectedFile4,
                      placeholder: 'add45back',
                      ref: fileInputRef4,
                      onChange: handleFileSelect4,
                    },
                  ].map((image, index) => (
                    <div key={index}>
                      <div
                        className='w-full h-[156px] md:h-[196px] xl:h-[168px] flex justify-center items-center bg-black-500 rounded-[10px]'
                        style={{
                          backgroundImage: image.file
                            ? `url(${image.file})`
                            : 'none',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                        onClick={() =>
                          isReadOnly || isProgress
                            ? undefined
                            : handleImageClick(image.ref)
                        }
                      >
                        {!image.file && (
                          <div className='flex flex-col justify-center items-center cursor-pointer text-center'>
                            <img src={placeholder_img} alt='add-img' />
                            <div
                              className='text-f_18_l_28 mt-3.5 break-all hyphens-auto'
                              style={{ wordBreak: 'break-word' }}
                            >
                              {t(image.placeholder)}
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type='file'
                        ref={image.ref}
                        style={{ display: 'none' }}
                        accept='image/png, image/jpeg, image/jpg'
                        onChange={image.onChange}
                        disabled={isReadOnly}
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Maintenance service */}
              <div className='mb-[53px] md:mb-[61px] xl:mb-10'>
                {/* Maintenance service TEXT */}
                <div className='flex flex-col xl:flex-row items-start xl:items-center justify-between mb-5 md:mb-[21px] xl:mb-8'>
                  <h3 className='text-f_20_l_25 md:text-f_22_l_28 xl:text-f_26_l_33 text-white-100 font-supremeMedium mb-4 xl:mb-0'>
                    {t('Maintainence_list_desc')}
                  </h3>
                  <div className='text-f_16_l_20 md:text-f_18_l_23 text-blue'>
                    {t('Maintainence_list_desc_2')}
                  </div>
                </div>
                {console.log('isProgress', isProgress)}
                <SurveyForm
                  isReadOnly={isReadOnly || isProgress}
                  handleUpdateComment={handleUpdateComment}
                  isProgress={isProgress}
                />
              </div>

              {/* Mechanic comment */}
              <div className='mb-7'>
                <h3 className='text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5'>
                  {t('Mechanic_comment')}
                </h3>

                <textarea
                  readOnly={isReadOnly ? true : undefined}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    if (isProgress) {
                      localStorage.setItem('mechanicComment', e.target.value);
                    }
                  }}
                  className='h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full'
                />
              </div>

              <Show IF={isReadOnly}>
                <div className='flex items-center justify-center w-full mb-10'>
                  <div className='bg-gradient-text font-supremeBold text-2xl w-auto px-4 py-2 text-center'>
                    {t('CREATED_BY', {
                      user: maintenanceData?.user?.name || 'unknown_user',
                      date: maintenanceData?.maintenance?.completed_at
                        ? formatDate(maintenanceData?.maintenance?.completed_at)
                        : 'N/A',
                      company:
                        maintenanceData?.user?.company?.company_name ||
                        'unknown_company',
                    })}
                  </div>
                </div>
              </Show>

              {/* SAVE SERVICE DATA BUTTON */}
              <Show IF={!isReadOnly}>
                <button
                  className='w-[270px] h-[52px] md:h-[62px] bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium disabled:border-gray-400 disabled:cursor-not-allowed'
                  onClick={isProgress ? handleUpdateMaintenance : handleSubmit}
                  // disabled={disableSaveButton || isReadOnly}
                >
                  {isProgress
                    ? t('Update_maintenance_data')
                    : t('Maintainence_button_text')}
                </button>
              </Show>
              <br />
            </div>
          </main>
        </div>
      </SurveyFormContext.Provider>
    </>
  ) : (
    <>
      <div className='flex'>
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

        <Header setOpenSidebar={setOpenSidebar} />

        <main className='md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full'>
          <h3 className='bg-gradient-text text-f_19_l_24 md:text-f_22_l_28 xl:text-f_27_l_34 text-left font-supremeBold'>
            No Maintainence found
          </h3>
        </main>
      </div>
    </>
  );
}

export default AddNewService;
